<template>
  <header class="header" id="header">
    <nav class="navbar navbar-expand-md bg-white">
      <div class="container-fluid container-lg ">
        <!-- 手機板 導覽列 開始 -->
        <!-- logo -->
        <router-link
          class="navbar-brand d-md-none mr-0 p-3 p-md-0"
          to="/"
          @click.native="OnClickHamberger"
        >
          <img class="nav__logo--img" src="@/assets/images/logo-mobile.png" alt="Logo" />
          <svg
            v-if="$DEV === '1'"
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="dev"
            width="24"
            height="24"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="svg-inline--fa fa-dev fa-w-14 fa-2x"
          >
            <path fill="currentColor" :d="devIcon" class=""></path>
          </svg>
        </router-link>
        <!-- 搜尋按鈕 -->
        <div class="d-md-none ml-auto mr-3">
          <a
            class="position-relative d-block link-hover-accent"
            id="btn-search"
            @click="OnClickHamberger"
            ><i class="fa fa-search fa-lg" aria-hidden="true"></i
          ></a>
        </div>
        <!-- 購物車 -->
        <div class="d-md-none mr-7">
          <router-link
            class="position-relative d-block link-hover-accent"
            to="/shopping/step1"
            @click.native="OnClickHamberger"
          >
            <i class="fa fa-shopping-cart fa-lg" aria-hidden="true"></i>
            <span class="icon-num">{{ getCartCount }}</span>
          </router-link>
        </div>
        <!-- hamburger -->
        <div
          id="hamberger"
          class="nav__hamberger d-md-none"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="openOverlay"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <!-- 手機板 導覽列 結束 -->
        <!-- 網頁版 導覽列 開始-->
        <div
          class="nav__menu collapse navbar-collapse justify-content-between align-items-center"
          id="navbarNavDropdown"
        >
          <ul class="navbar-nav align-items-center">
            <!-- logo -->
            <li class="nav__logo d-none d-md-block nav__item nav-item">
              <h1>
                <router-link class="d-block mr-md-5" to="/">
                  <img class="nav__logo--img" src="@/assets/images/logo.png" alt="Logo" />

                  <svg
                    v-if="$DEV === '1'"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="dev"
                    width="24"
                    height="24"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="svg-inline--fa fa-dev fa-w-14 fa-2x"
                  >
                    <path fill="currentColor" :d="devIcon" class=""></path>
                  </svg>
                </router-link>
              </h1>
            </li>
            <!-- 導覽列 (網頁板/手機板) -->
            <li class="nav__item nav-item mx-md-3" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/course">
                購買課程
              </router-link>
            </li>
            <li class="nav__item nav-item mx-md-3" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/bookings">
                預約講堂
              </router-link>
            </li>
            <li class="nav__item nav-item mx-md-3" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/teachers">
                師資團隊
              </router-link>
            </li>
            <li class="nav__item nav-item mx-md-3" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/learnings">
                上課去
              </router-link>
            </li>
            <!-- <li class="nav__item nav-item mx-md-3" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/practices">
                測驗去
              </router-link>
            </li> -->
            <!-- 會員 (手機板 ) -->
            <li class="nav__item nav-item d-md-none" v-if="UserAccount" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5 message" to="/member/message">
                會員訊息
                <span class="icon-num">{{ UnReadCount }}</span>
              </router-link>
            </li>
            <li class="nav__item nav-item d-md-none" v-if="UserAccount" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/member/feedBacks">
                意見回饋
              </router-link>
            </li>
            <li class="nav__item nav-item d-md-none" v-if="UserAccount" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/member/orders">
                查詢訂單
              </router-link>
            </li>
            <li v-if="UserAccount" class="nav__item nav-item d-md-none"
              @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/member/info">
                會員資訊
              </router-link>
            </li>
            <li class="nav__item nav-item d-md-none" v-if="UserAccount" @click="OnClickHamberger">
              <router-link class="nav-link py-3 px-md-0 py-md-5" to="/member/changePassword">
                密碼修改
              </router-link>
            </li>
            <li class="nav__item nav-item d-md-none" @click="OnClickHamberger">
              <router-link v-if="!UserAccount" class="nav-link py-3 px-md-0 py-md-5" to="/login">
                登入
              </router-link>
              <a v-else class="nav-link py-3 px-md-0 py-md-5" @click.prevent="Logout()">登出</a>
            </li>
            <!-- search -->
            <li class="ml-md-3 ml-lg-6">
              <form class="search__form d-none d-lg-block" @submit.prevent="Search">
                <input
                  type="search"
                  value=""
                  placeholder="課程搜尋"
                  class="search__input"
                  id="h-search"
                  v-model="searchContent"
                />
                <!-- <router-link
                  class="search__button"
                  :to="{ name: 'Search', query: { searchContent } }"
                >
                  <i class="fa fa-search text-muted" aria-hidden="true"></i>
                </router-link> -->
                <button type="submit" class="search__button">
                  <i class="fa fa-search text-muted" aria-hidden="true"></i>
                </button>
              </form>
            </li>
          </ul>
          <!-- icon (網頁版導覽列) 開始 -->
          <div class="d-none d-md-flex align-items-center">
            <!-- 購物車 按鈕 -->
            <div class="mr-md-7">
              <router-link class="position-relative d-block link-hover-accent" to="/shopping/step1">
                <i class="fa fa-shopping-cart fa-lg" aria-hidden="true"></i>
                <span class="icon-num">{{ getCartCount }}</span>
              </router-link>
            </div>
            <!-- 會員 按鈕 -->
            <div class="mr-md-7 dropdown" v-if="UserAccount">
              <a
                href="#"
                class="position-relative d-block link-hover-accent"
                id="navbarDropdown2"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-user fa-lg" aria-hidden="true"></i>
                <span class="icon-num">{{ UnReadCount }}</span>
              </a>
              <ul class="dropdown-menu text-center mt-0" aria-labelledby="navbarDropdown2">
                <li class="dropdown">
                  <router-link class="dropdown-item message" to="/member/message">
                    會員訊息
                    <span class="icon-num">{{ UnReadCount }}</span>
                  </router-link>
                </li>
                <li class="dropdown-divider"></li>
                <li class="dropdown">
                  <router-link class="dropdown-item" to="/member/feedBacks">
                    意見回饋
                  </router-link>
                </li>
                <li class="dropdown-divider"></li>
                <li class="dropdown">
                  <router-link class="dropdown-item" to="/member/orders">
                    查詢訂單
                  </router-link>
                </li>
                <li class="dropdown-divider"></li>
                  <li class="dropdown">
                  <router-link class="dropdown-item" to="/member/info">
                    會員資訊
                  </router-link>
                </li>
                <li class="dropdown-divider"></li>
                <li class="dropdown">
                  <router-link class="dropdown-item" to="/member/changePassword">
                    密碼修改
                  </router-link>
                <!-- 選單內容列表 第二層 沒用到，但先保留以後可以用-->
                <!-- <ul class="dropdown-menu mt-0" aria-labelledby="navbarDropdown">
                      <li class="dropdown">
                          <a class="dropdown-item" href="#">Action</a>
                      </li>
                      <li class="dropdown">
                          <a class="dropdown-item" href="#">Another action</a>
                      </li>
                    </ul> -->
                </li>
              </ul>
            </div>
            <!-- 登入 按鈕 -->
            <router-link
              v-if="!UserAccount"
              class="btn btn-primary text-nowrap rounded-xl  btn-hover-primary px-md-5"
              to="/login"
            >
              登入
            </router-link>
            <a
              v-else
              class="btn btn-primary text-nowrap rounded-xl  btn-hover-primary px-md-5"
              @click.prevent="Logout()"
              >登出</a
            >
          </div>
          <!-- icon (網頁版導覽列) 結束 -->
        </div>
        <!-- 網頁版 導覽列 結束-->
      </div>
    </nav>
    <!-- 手機板 search 開始 -->
    <form
      class="search__form search__form--mobile d-md-none"
      id="search-box"
      @submit.prevent="Search"
    >
      <div>
        <input
          type="search"
          value=""
          placeholder="課程搜尋"
          class="search__input"
          id="h-search"
          v-model="searchContent"
        />
        <a class="search__button" @click.prevent="Search"
          ><i class="fa fa-search text-muted" aria-hidden="true"></i
        ></a>
      </div>
    </form>
    <!-- 背景遮罩 -->
    <div class="header__overlay" @click="OnClickHamberger" />
  </header>
</template>

<script>
import { Logout } from '@/api/LoginApi';
import { GetMessageUnReadCount } from '@/api/MemberApi';
import { GetShoppingCartCount, AddShoppingCart } from '@/api/ShoppingCartApi';

/* global $ */
export default {
  data() {
    return {
      devIcon:
        'M120.12 208.29c-3.88-2.9-7.77-4.35-11.65-4.35H91.03v104.47h17.45c3.88 0 7.77-1.45 11.65-4.35 3.88-2.9 5.82-7.25 5.82-13.06v-69.65c-.01-5.8-1.96-10.16-5.83-13.06zM404.1 32H43.9C19.7 32 .06 51.59 0 75.8v360.4C.06 460.41 19.7 480 43.9 480h360.2c24.21 0 43.84-19.59 43.9-43.8V75.8c-.06-24.21-19.7-43.8-43.9-43.8zM154.2 291.19c0 18.81-11.61 47.31-48.36 47.25h-46.4V172.98h47.38c35.44 0 47.36 28.46 47.37 47.28l.01 70.93zm100.68-88.66H201.6v38.42h32.57v29.57H201.6v38.41h53.29v29.57h-62.18c-11.16.29-20.44-8.53-20.72-19.69V193.7c-.27-11.15 8.56-20.41 19.71-20.69h63.19l-.01 29.52zm103.64 115.29c-13.2 30.75-36.85 24.63-47.44 0l-38.53-144.8h32.57l29.71 113.72 29.57-113.72h32.58l-38.46 144.8z',
      UserAccount: '',
      searchContent: '',
      activeSearch: '',
      UnReadCount: 0,
      getUnReadCounting: 0,
      getCartCount: 0,
    };
  },
  created() {
    // ('對應傳送端的emit方法','接收的參數')
    this.$bus.$on('setUserAccount', (val) => {
      this.SetUserAccount(val);
    });

    // ('對應傳送端的emit方法','接收的參數')
    this.$bus.$on('getUnReadCount', (force) => {
      this.GetMessageUnReadCount(force);
    });

    // ('對應傳送端的emit方法','接收的參數')
    this.$bus.$on('getShoppingCartCount', () => {
      this.GetShoppingCartCount();
    });

    // ('對應傳送端的emit方法','接收的參數')
    this.$bus.$on('addShoppingCart', (id) => {
      this.AddShoppingCart(id);
    });
  },
  mounted() {
    this.$setUserAccount();
    if (this.UserAccount !== '') this.GetMessageUnReadCount();
    if (this.UserAccount !== '') this.GetShoppingCartCount();
    $('#btn-search').click(() => {
      $('#search-box').toggleClass('open');
    }); // side

    $('.navbar-nav>li').on('click', () => {
      $('#search-box').removeClass('open');
      $('.navbar-collapse').collapse('hide');
    });

    $('#hamberger').click(() => {
      // $(this).toggleClass('open');
      $('#search-box').removeClass('open');
    }); // gototop
  },
  methods: {
    openOverlay() {
      $('body').toggleClass('open');
      $('.header').toggleClass('open');
    },
    OnClickHamberger() {
      $('#search-box').removeClass('open');
      $('.navbar-collapse').collapse('hide');
      $('body').removeClass('open');
      $('.header').removeClass('open');
    },
    SetUserAccount(val) {
      this.UserAccount = val;
      if (val === '') this.getCartCount = 0;
    },
    Search() {
      if (this.searchContent.length < 2) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '課程搜尋至少輸入兩個字',
        });
      } else if (this.activeSearch !== this.searchContent || this.$route.name !== 'Search') {
        this.activeSearch = this.searchContent;
        $('#search-box').toggleClass('open');
        this.$router.push({ path: '/Search', query: { searchContent: this.activeSearch } });
      } else {
        $('#search-box').toggleClass('open');
        // 當在搜尋結果頁面，使用相同條件再次搜尋的話
        this.$bus.$emit('reSearchCourse', this.searchContent);
      }
    },
    Logout() {
      return Logout().then((res) => {
        if (res.data.statusCode === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.data.message,
          });
          const temp = new Date();
          const d = new Date(temp.setDate(temp.getDate() - 1));
          document.cookie = `token=;expires=${d}; path=/`;
          document.cookie = `CloudFront-Policy=; expires=${d}; path=/`;
          document.cookie = `CloudFront-Key-Pair-Id=; expires=${d}; path=/`;
          document.cookie = `CloudFront-Signature=; expires=${d}; path=/`;
          this.$setUserAccount();
          // this.GetShoppingCartCount();
          if (this.$route.path !== '/') this.$router.push('/');
        }
      });
    },
    AddShoppingCart(id) {
      this.$setUserAccount();
      if (this.UserAccount !== '') {
        AddShoppingCart(id).then((response) => {
          if (response.data.statusCode === 0) {
            this.$message({
              showClose: true,
              type: 'success',
              message: '成功加入購物車!',
            });
          }
          this.GetShoppingCartCount();
        });
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '請先登入會員。',
        });
      }
    },
    GetShoppingCartCount() {
      this.$setUserAccount();
      if (this.UserAccount !== '') {
        GetShoppingCartCount().then((response) => {
          if (response.data.statusCode === 0) {
            this.getCartCount = response.data.data;
          }
        });
      } else {
        this.getCartCount = 0;
      }
    },
    GetMessageUnReadCount(force = false) {
      this.$setUserAccount();
      if (this.UserAccount !== '') {
        if (this.getUnReadCounting === 0 || force === true) {
          this.getUnReadCounting = 1;

          GetMessageUnReadCount().then((response) => {
            if (response.data.statusCode === 0) {
              this.UnReadCount = response.data.data;
            }
          });

          // 避免短時間一直重新取得
          setTimeout(() => {
            this.getUnReadCounting = 0;
          }, 30000);
        }
      }
    },
  },
};
</script>

<style></style>
