<template>
  <article class="cs-title mb-4" data-style="2">
    <h2 class="content" :data-title="currentRouteName"></h2>
  </article>
</template>

<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style></style>
