const TeacherRouter = [
  {
    path: '/teachers',
    name: 'teachers',
    component: () => import('../../views/Teacher/Teachers.vue'),
    meta: {
      title: '師資團隊',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/teachers/:id',
    name: 'teacher',
    component: () => import('../../views/Teacher/Teacher.vue'),
    meta: {
      title: '師資團隊',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
];

export default TeacherRouter;
