const LearningRouter = [
  {
    path: '/learnings',
    name: 'learnings',
    component: () => import('../../views/Learning/Learnings.vue'),
    meta: {
      title: '上課去',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/learnings/:id',
    name: 'learning',
    component: () => import('../../views/Learning/Learning.vue'),
    meta: {
      title: '上課去',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'learnings',
    },
  },
];

export default LearningRouter;
