<template>
  <main class="mb-auto">
    <PageTitle v-if="$route.meta.haveTitle" />
    <section class="container" v-if="$route.meta.haveBreadcrumb">
      <Breadcrumb :currentPage.sync="currentPage" />
    </section>
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive && $route.meta.layout !== 'error'"
        :currentPage.sync="currentPage"
      />
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive && $route.meta.layout !== 'error'"
      :currentPage.sync="currentPage"
    />

    <router-view v-if="$route.meta.layout === 'error'" />
  </main>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
  components: {
    PageTitle,
    Breadcrumb,
  },
  data() {
    return {
      currentPage: [],
    };
  },
};
</script>
