import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** Members api */

/**
 * 會員註冊
 * @param {object} data {
  "memberId": 0,
  "memberAccount": "string",
  "memberPassword": "string",
  "memberName": "string",
  "memberMail": "string",
  "memberPhone": "string",
  "memberZipCode": "string",
  "memberAddress": "string",
  "isReceive": 0,
  "status": 0,
  "code": "string",
  "captchaCode": "string"
  }
 */
export const RegistMember = (data) => baseAxios.post('FrontEnd/Members/RegistMember', data);

/**
 * 取得會員資料
 * @param {object} data { }
 */
export const MemberById = (data) => baseAxios.post('FrontEnd/Members/MemberById', data);

/**
 * 取得會員訊息
 * @param {object} data { }
 */
export const GetMembetInfoData = () => baseAxios.post('FrontEnd/Members/GetMembetInfoData', {});

/**
 * 會員資料修改
 * @param {object} data {
  "memberName": "string",
  "memberMail": "string",
  "memberPhone": "string",
  "memberZipCode": "string",
  "memberAddress": "string",
  "inSchool": 0,
  "schoolId": 0,
  "schoolDeptId": 0,
  "schoolYearId": 0
}
 */
export const ModifyMember = (data) => baseAxios.post('FrontEnd/Members/ModifyMember', data);

/**
 * 會員修改密碼
 * @param {object} data {
  "memberPassword": "string",
  "memberNewPassword": "string",
  "memberConfirmPassword": "string"
}
 */
export const ModifyMemberPassword = (data) => baseAxios.post('FrontEnd/Members/ModifyMemberPassword', data);

/**
 * 忘記密碼
 * @param {object} data {
  "memberAccount": "string",
  "code": "string",
  "captchaCode": "string"
}
 */
export const MemberForgetPassword = (data) => baseAxios.post('FrontEnd/Members/MemberForgetPassword', data);

/**
 * 忘記密碼-發送驗證信(一分鐘一次)
 * @param {object} data {
  "memberAccount": "string",
  "guid": "string"
}
 */
export const MemberSendCaptchaCode = (data) => baseAxios.post('FrontEnd/Members/MemberSendCaptchaCode', data);

/**
 * 忘記密碼-驗證信箱的驗證碼
 * @param {object} data {
  "memberAccount": "string",
  "captchaCode": "string",
  "guid": "string"
}
 */
export const MemberCheckCaptchaCode = (data) => baseAxios.post('FrontEnd/Members/MemberCheckCaptchaCode', data);

/**
 * 忘記密碼-修改密碼
 * @param {object} data {
  "memberAccount": "string",
  "newGuid": "string",
  "memberNewPassword": "string",
  "memberConfirmPassword": "string"
}
 */
export const SaveMemberForgetPassword = (data) => baseAxios.post('FrontEnd/Members/SaveMemberForgetPassword', data);

/**
 * 會員訊息夾
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
}
 */
export const GetMemberMessage = (data) => baseAxios.post('FrontEnd/Members/MemberMessage', data);

/**
 * 會員訊息內容
 * @param {object} data { id }
 */
export const GetMemberMessageById = (id) => baseAxios.post('FrontEnd/Members/MemberMessageById', { id });

/**
 * 全部已讀
 * @param {object} data { }
 */
export const ReadAllMemberMessage = (data) => baseAxios.post('FrontEnd/Members/ReadAllMemberMessage', data);

/**
 * 會員訊息 未讀數量(紅圈圈通知)
 * @param {object} data { }
 */
export const GetMessageUnReadCount = function GetMessageUnReadCount() {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('FrontEnd/Members/MemberMessageUnReadCount');
};

/**
 * 忘記帳號
 * @param {object} data {
  "memberMail": "string",
  "memberPhone": "string",
  "code": "string",
  "captchaCode": "string"
}
 */
export const MemberForgetAccount = (data) => baseAxios.post('FrontEnd/Members/MemberForgetAccount', data);

/**
 * 會員信箱綁定
 * @param {object} data {
  "data": "string",
  "verificationGuid": "string",
  "verificationCaptchaCode": "string"
}
 */
export const ModifyMemberMail = (data) => baseAxios.post('FrontEnd/Members/ModifyMemberMail', data);

/**
 * 會員手機綁定
 * @param {object} data {
  "data": "string",
  "verificationGuid": "string",
  "verificationCaptchaCode": "string"
}
 */
export const ModifyMemberPhone = (data) => baseAxios.post('FrontEnd/Members/ModifyMemberPhone', data);

export const BindMemberLine = (data) => baseAxios.post('/FrontEnd/Members/BindMemberLine', data);

/**
 * 取得學校下拉
 * @param {object} data { type }
 */
export const GetSchool = (type = 99) => baseAxios.post('FrontEnd/Members/GetSchool', { type });

/**
 * 取得科系下拉
 * @param {object} data {
  "schoolId": 1,
  "type": 99
}
 */
export const GetSchoolDept = (data) => baseAxios.post('FrontEnd/Members/GetSchoolDept', data);

/**
 * 取得年級下拉
 * @param {object} data { type }
 */
export const GetSchoolYear = (type = 99) => baseAxios.post('FrontEnd/Members/GetSchoolYear', { type });

/**
 * 會員學歷修改
 * @param {object} data {
  "inSchool": 0,
  "schoolId": 0,
  "schoolDeptId": 0,
  "schoolYearId": null
}
 */
export const ModifyMemberSchool = (data) => baseAxios.post('FrontEnd/Members/ModifyMemberSchool', data);
