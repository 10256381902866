import Vue from 'vue';
import MainFrame from '@/layouts/default.vue';
import ErrorFrame from '@/layouts/error.vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import isotope from 'vueisotope';
import VueAxios from 'vue-axios';
import axios from 'axios';
import _ from 'lodash';
import {
  MessageBox,
  Message,
  Form,
  FormItem,
  Input,
  Dialog,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Radio,
  RadioGroup,
  RadioButton,
  Progress,
} from 'element-ui';
import twLocale from 'element-ui/lib/locale/lang/zh-TW';
import locale from 'element-ui/lib/locale';
import VueAppend from 'vue-append';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'element-ui/lib/theme-chalk/index.css';
import '@/plugins/jquery';

// Bus
import './bus';
// Filters
import './lib/Filters';

Vue.config.productionTip = false;
Vue.component('mainframe', MainFrame);
Vue.component('errorFrame', ErrorFrame);
Vue.component('isotope', isotope);

Vue.use(VueMoment, { moment });
// 设置语言
locale.use(twLocale);
// 套件加入到Vue的藍圖內(原型內)
Vue.use(VueAxios, axios);
Vue.use(VueAppend);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Progress);

Vue.prototype._ = _;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$UserId = '';
Vue.prototype.$UserAccount = '';
Vue.prototype.$UserName = '';
Vue.prototype.$IsBinding = '';
Vue.prototype.$DEV = process.env.VUE_APP_DEV;
Vue.prototype.$MAINTAIN = process.env.VUE_APP_MAINTAIN;
// Vue.prototype.$source = '';

Vue.prototype.$setUserAccount = () => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
  Vue.prototype.$UserAccount = '';
  if (token) {
    const jsonObj = JSON.parse(
      decodeURIComponent(
        escape(
          window.atob(
            token
              .split('.')[1]
              .replace(/-/g, '+')
              .replace(/_/g, '/'),
          ),
        ),
      ),
    );
    Vue.prototype.$UserId = jsonObj.UserId;
    Vue.prototype.$UserAccount = jsonObj.UserAccount;
    Vue.prototype.$UserName = jsonObj.UserName;
    Vue.prototype.$IsBinding = jsonObj.IsBinding;
  }
  Vue.prototype.$bus.$emit('setUserAccount', Vue.prototype.$UserAccount);
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
