import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

Vue.use(VueMoment, { moment });
Vue.filter('toTaipei', (date) => {
  if (date !== null) {
    return Vue.prototype.$moment
      .utc(date)
      .tz('Asia/Taipei')
      .format();
  }
  return '';
});

Vue.filter('toUtc', (date) => {
  if (date !== null) {
    return Vue.prototype.$moment.utc(date).format();
  }
  return '';
});

Vue.filter('toCurrency', (num) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${parts.join('.')}`;
});

Vue.filter('secConvertTime', (seconds) => {
  if (seconds !== null) {
    return Vue.prototype
      .$moment()
      .startOf('day')
      .add(seconds, 'seconds')
      .format('HH:mm:ss');
  }
  return '';
});

// export default function (num) {
//   const n = Number(num);
//   return `NT $${n.toFixed(0).replace(/./g, (c, i, a) => {
//     const currency = i && c !== '.' && (a.length - i) % 3 === 0 ? `,
// ${c}`.replace(/\s/g, '') : c;
//     return currency;
//   })}`;
// }
