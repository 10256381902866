<template>
  <!-- 底部 -->
  <footer class="footer bg-accent py-3">
    <div class="container">
      <div class="footer__top text-white mb-md-8 px-3 px-md-0 pt-3">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="footer__logo order-1 order-md-0">
            <h2>
              <router-link to="/">
                <img src="@/assets/images/footer_logo.png" alt="Logo" />
              </router-link>
            </h2>
          </div>
          <ul
            class="footer__menu
              d-flex
              align-self-stretch
              flex-wrap
              order-0
              order-md-1"
          >
            <li class="footer__menu--block">
              <h5 class="mb-3">
                <router-link class="text-white" to="/novices">
                  新手指南
                </router-link>
              </h5>
              <ul class="footer__menu--item d-flex flex-wrap">
                <li class="">
                  <router-link to="/novices/policy">
                    服務條款
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/novices/buyNote">
                    購買須知
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/novices/about">
                    關於我們
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/novices/class">
                    班別介紹
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/novices/buyProcess">
                    購買流程
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/novices/question">
                    常見問題
                  </router-link>
                </li>
                <li class="">
                  <router-link to="/novices/contact">
                    聯絡我們
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="footer__menu--block">
              <h5 class="mb-3"><a class="text-white" href="#">相關連結</a></h5>
              <ul class="footer__menu--item d-flex flex-wrap">
                <li class="" v-for="(item, index) in data" :key="index">
                  <a :href="item.homeLinkUrl" target="_blank">{{ item.homeLinkName }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom px-3 px-md-0  pb-3">
        <div class="row justify-content-between align-items-center">
          <div class="footer__copyright col-12 col-md-10 mb-6 mb-md-0">
            <p class="mb-2 mb-md-0">
              Copyright© 2021 讀家數位文化股份有限公司 All rights reserved.
            </p>
            <p>
              地址：100007 台北市中正區館前路8號4樓<br />
              TEL：02-7726-6667<br />
              E-MAIL：service@readerplace.com.tw
            </p>
          </div>
          <ul class="col-12 col-md-2 d-flex justify-content-center align-items-center">
            <li class="mx-3">
              <a href="https://www.facebook.com/readerplace0129/" target="_blank"
                ><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i
              ></a>
            </li>
            <li class="mx-3">
              <a href="https://www.youtube.com/channel/UCIPJw-lW_7N-xb2keII0gCQ" target="_blank"
                ><i class="fa fa-youtube fa-2x" aria-hidden="true"></i
              ></a>
            </li>
            <!-- <li class="mx-3">
                <a href=""
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <path
                            d="M0 0H35V35H0z"
                            transform="translate(-1197 -293) translate(1193 289)"
                          />
                          <path
                            fill="#FFF"
                            :d="lineIcon"
                            transform="translate(-1197 -293) translate(1193 289)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li> -->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import GetHomelinks from '@/api/HomelinksApi';

/* global $ */
export default {
  data() {
    return {
      data: [],
      lineIcon:
        'M21.8 4.2c4.97 0 9 4.03 9 9v8.6c0 4.97-4.03 9-9 9h-8.6c-4.97 0-9-4.03-9-9v-8.6c0-4.97 4.03-9 9-9h8.6zm-4.65 3.527C11.555 7.727 7 11.464 7 16.057c0 4.115 3.612 7.562 8.488 8.217.33.07.78.221.895.505.101.258.067.655.032.924l-.139.872c-.038.257-.203 1.014.888.551 1.092-.46 5.85-3.487 7.98-5.965 1.459-1.615 2.156-3.27 2.156-5.103 0-4.594-4.555-8.331-10.15-8.331zm-5.991 5.905c.294 0 .533.244.533.54v3.54h1.485c.294 0 .532.243.532.54 0 .293-.238.537-.532.537H11.16c-.292 0-.533-.244-.533-.538v-4.08c0-.295.241-.539.533-.539zm3.573 0c.292 0 .53.244.53.54v4.08c0 .293-.238.537-.533.537-.292 0-.53-.244-.53-.538v-4.08c0-.295.238-.539.533-.539zm8.65 0c.293 0 .53.244.53.54 0 .297-.237.538-.532.538h-1.485v.962h1.485c.295 0 .532.244.532.54 0 .295-.237.538-.532.538h-1.485v.962h1.485c.295 0 .532.243.532.54 0 .293-.237.537-.532.537H21.36c-.291 0-.53-.244-.53-.538v-4.08c0-.295.239-.539.533-.539h2.018zm-3.796 0c.292 0 .533.244.533.54v4.08c0 .23-.147.435-.366.51-.054.017-.112.026-.168.026-.178 0-.33-.078-.431-.215l-2.067-2.836v2.513c0 .294-.236.538-.533.538-.293 0-.53-.244-.53-.538v-4.079c0-.231.147-.436.364-.509.05-.02.115-.028.164-.028.165 0 .317.09.419.218l2.082 2.847v-2.528c0-.295.239-.539.533-.539z',
    };
  },
  created() {
    this.GetList();
  },
  mounted() {
    // Top
    let s;
    $(window).scroll(() => {
      s = $(document).scrollTop();
      /* 偵測卷軸滑動時，往下滑超過400px就讓GoTop按鈕出現 */

      if (s > $('#header').outerHeight()) {
        $('#header').addClass('mini-nav');
        $('#gotop').fadeIn();
      } else {
        $('#header').removeClass('mini-nav');
        $('#gotop').fadeOut();
      }
    });

    $('#gotop').click(() => {
      $('html,body').animate(
        {
          scrollTop: 0,
        },
        'slow',
      );
      /* 返回到最頂上 */
      return false;
    }); // search
  },
  methods: {
    GetList() {
      GetHomelinks().then((response) => {
        if (response.data.statusCode === 0) {
          this.data = response.data.data;
        }
      });
    },
  },
};
</script>
