import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** product api */
/**
 * 加入購物車
 * @param {object} data { id }
 */
export const AddShoppingCart = (id) => baseAxios.post('/FrontEnd/ShoppingCart/AddShoppingCart', { id });

/**
 * 取得購物車數量
 */
export const GetShoppingCartCount = function GetShoppingCartCount() {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('/FrontEnd/ShoppingCart/GetShoppingCartCount');
};

/**
 * 1.取得購物車 2.取得訂購明細及付款資訊 3.取得確定結帳資訊
 * @param {object} data {}
 */
export const GetShoppingCart = () => baseAxios.post('/FrontEnd/ShoppingCart/GetShoppingCart');

/**
 * 刪除課程
 * @param {object} data {id}
 */
export const DeleteShoppingCartByCourse = (id) => baseAxios.post('/FrontEnd/ShoppingCart/DeleteShoppingCartByCourse', { id });

/**
 * 取得優惠
 * @param {object} data {}
 */
export const GetDiscountList = () => baseAxios.post('/FrontEnd/ShoppingCart/GetDiscountList');

/**
 * 取得優惠
 * @param {object} data {code}
 */
export const GetDiscountDetail = (code) => baseAxios.post('/FrontEnd/ShoppingCart/GetDiscountDetail', { code });

/**
 * 取得付款模式下拉選項
 * @param {object} data {}
 */
export const GetOrderPayMode = (type) => baseAxios.post('/FrontEnd/ShoppingCart/GetOrderPayMode', { type });

/**
 * 取得發票模式選項
 * @param {object} data {}
 */
export const GetOrderInvoiceType = (type) => baseAxios.post('/FrontEnd/ShoppingCart/GetOrderInvoiceType', { type });

/**
 * save and next 儲存購物資料 1.3 下一步、 2.2結帳
 * @param {object} data {
                        "orderId": 0,
                        "discountId": 0,
                        "discountMode": 0,
                        "discountName": "string",
                        "discountCode": "string",
                        "discountType": 0,
                        "discountPrice": 0,
                        "discountPercent": 0,
                        "discountMoney": 0,
                        "orderName": "string",
                        "orderPhone": "string",
                        "orderZipCode": "string",
                        "orderAddress": "string",
                        "orderNote": "string",`
                        "orderPayMode": 0,
                        "orderPayModeName": "string"
                      }
 */
export const SaveShoppingCart = (data) => baseAxios.post('/FrontEnd/ShoppingCart/SaveShoppingCart', data);

/**
 * 3.1 確認付款(開始接金流)
 * @param {object} data {}
 */
export const PayShoppingCart = () => baseAxios.post('/FrontEnd/ShoppingCart/PayShoppingCart');

/**
 * 取得超商代碼取號資料
 * @param {object} data {code}
 */
export const GetEcPayPaymentInfo = (code) => baseAxios.post('/FrontEnd/ShoppingCart/GetEcPayPaymentInfo', { code });

/**
 * 1.取得訂單的購物車
 * @param {object} data {id}
 */
export const GetOrderCart = (id) => baseAxios.post('/FrontEnd/ShoppingCart/GetOrderCart', { id });

/**
 * save and next 儲存訂單購物資料 1.3 下一步、 2.2結帳
 * @param {object} data {
                        "orderId": 0,
                        "discountId": 0,
                        "discountMode": 0,
                        "discountName": "string",
                        "discountCode": "string",
                        "discountType": 0,
                        "discountPrice": 0,
                        "discountPercent": 0,
                        "discountMoney": 0,
                        "orderName": "string",
                        "orderPhone": "string",
                        "orderZipCode": "string",
                        "orderAddress": "string",
                        "orderNote": "string",`
                        "orderPayMode": 0,
                        "orderPayModeName": "string"
                      }
 */
export const SaveOrderCart = (data) => baseAxios.post('/FrontEnd/ShoppingCart/SaveOrderCart', data);

/**
 * 3.1 確認認付款(開始接金流)
 * @param {object} data {}
 */
export const PayOrderCart = (id) => baseAxios.post('/FrontEnd/ShoppingCart/PayOrderCart', { id });

/**
 * 檢查手機條碼
 * @param {object} data {
 *                       verificationType: 固定給3即可
 *                       verificationData: 手機條碼
 *                        }
 */
export const CheckEcPayBarcode = function CheckEcPayBarcode(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('/FrontEnd/ShoppingCart/CheckEcPayBarcode', data);
};

/**
 * 檢查統一編號
 * @param {object} data { Code: 統一編號 }
 */
export const CheckInvoiceUid = function CheckInvoiceUid(data) {
  baseAxios.defaults.showLoading = false;
  return baseAxios.post('/FrontEnd/ShoppingCart/CheckInvoiceUid', data);
};
