import Vue from 'vue';

Vue.prototype.$bus = new Vue();

// Loading
// this.$bus.$emit('setLoading');

// setUserAccount
// this.$bus.$emit('setUserAccount');

// getUnReadCount
// this.$bus.$emit('getUnReadCount');

// getShoppingCartCount
// this.$bus.$emit('getShoppingCartCount');

// addShoppingCart
// this.$bus.$emit('addShoppingCart', id);

// 當在搜尋結果頁面，使用相同條件再次搜尋的話 (前提是，要先有到搜尋結果頁面，此方法才會被定義)
// this.$bus.$emit('reSearchCourse', this.searchContent);
