<template>
  <component :is="layout"> </component>
</template>

<script>
import { Loading } from 'element-ui';
import AOS from 'aos';

/* global $ */
let LoadingObj;
export default {
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      isLoading: false,
      Force: false,
    };
  },
  created() {
    // ('對應傳送端的emit方法','接收的參數')
    this.$bus.$on('setLoading', (isTrue) => {
      this.setLoading(isTrue);
    });

    this.$bus.$on('pushMessage', (type, message) => {
      this.pushMessage(type, message);
    });
  },
  mounted() {
    AOS.init();

    // 打開漢堡堡?
    $('#hamberger').click(() => {
      $(this).toggleClass('open');
    }); // gototop
  },
  methods: {
    setLoading(isTrue) {
      clearTimeout(this.timeoutId);
      if (isTrue) {
        LoadingObj = Loading.service({ fullscreen: true });
        this.isLoading = true;
      } else {
        LoadingObj.close();
        this.isLoading = false;
      }
    },
    pushMessage(type, message) {
      this.$message({
        showClose: true,
        type,
        message,
      });
    },
  },
};
</script>
<style lang="scss">
@import './assets/scss/all.scss';

.el-message-box {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}
</style>
