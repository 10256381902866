import Vue from 'vue';
import axios from 'axios';
import router from '../router';

// const loader = Vue.$loading.show();
// let loaderCount = 0;
const baseAxios = axios.create({
  baseURL: `${process.env.VUE_APP_APIPATH}`,
  showLoading: true,
  requestCount: 0,
  timeoutId: 0,
});

// 添加请求拦截器
baseAxios.interceptors.request.use(
  (config) => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const setConfig = config;
    clearTimeout(baseAxios.defaults.timeoutId);
    if (setConfig.showLoading) Vue.prototype.$bus.$emit('setLoading', true);
    baseAxios.defaults.showLoading = true;
    baseAxios.defaults.requestCount += 1;
    if (token) {
      setConfig.headers.Authorization = `Bearer ${token}`;
    }
    // 延遲送出請求
    return new Promise((resolve) => setTimeout(() => {
      resolve(config);
    }, 600));

    // return config;
  },
  (error) => Promise.reject(error),
);

const httpCode = {
  460: () => {
    const temp = new Date();
    const d = new Date(temp.setDate(temp.getDate() - 1));
    document.cookie = `token=;expires=${d}; path=/`;
    document.cookie = `CloudFront-Policy=; expires=${d}; path=/`;
    document.cookie = `CloudFront-Key-Pair-Id=; expires=${d}; path=/`;
    document.cookie = `CloudFront-Signature=; expires=${d}; path=/`;
    Vue.prototype.$setUserAccount();
    router.push('/error460').catch(() => {});
  },
  401: (msg) => {
    Vue.prototype.$bus.$emit('pushMessage', 'error', msg);
  },
  402: (msg) => {
    const temp = new Date();
    const d = new Date(temp.setDate(temp.getDate() - 1));
    document.cookie = `token=;expires=${d}; path=/`;
    document.cookie = `CloudFront-Policy=; expires=${d}; path=/`;
    document.cookie = `CloudFront-Key-Pair-Id=; expires=${d}; path=/`;
    document.cookie = `CloudFront-Signature=; expires=${d}; path=/`;
    Vue.prototype.$bus.$emit('pushMessage', 'error', msg);
    Vue.prototype.$setUserAccount();
    router.push('/login').catch(() => {});
  },
  403: (msg) => {
    Vue.prototype.$bus.$emit('pushMessage', 'error', msg);
  },
};

// http 攔截器
baseAxios.interceptors.response.use(
  (response) => {
    if (response.config.url !== '/FrontEnd/Captcha/GetCaptchaBase64') {
      if (response.data.statusCode !== 0) {
        Vue.prototype.$bus.$emit('pushMessage', 'error', response.data.message);
      }
    }
    // 每次請求都會去取得會員訊息筆數，每三十秒可以觸發一次
    // 但如果是在會員訊息頁面則強制取得
    if (response.config.url !== '/FrontEnd/Login/Logout') {
      if (
        response.config.url !== 'FrontEnd/Members/MemberMessageUnReadCount'
        && response.config.url !== 'FrontEnd/Members/ModifyMemberPhone'
        && response.config.url !== 'FrontEnd/Members/ModifyMemberMail'
      ) {
        const Force = response.config.url === 'FrontEnd/Members/ReadAllMemberMessage'
          || response.config.url === 'FrontEnd/Members/MemberMessageById'
          || response.config.url === 'FrontEnd/Members/MemberMessage';
        Vue.prototype.$bus.$emit('getUnReadCount', Force);
      }
    }

    baseAxios.defaults.requestCount -= 1;
    if (baseAxios.defaults.requestCount <= 0) {
      baseAxios.defaults.timeoutId = setTimeout(() => {
        Vue.prototype.$bus.$emit('setLoading', false);
      }, 100);
    }

    return response;
  },
  (error) => {
    baseAxios.defaults.requestCount -= 1;
    if (baseAxios.defaults.requestCount <= 0) {
      baseAxios.defaults.timeoutId = setTimeout(() => {
        Vue.prototype.$bus.$emit('setLoading', false);
      }, 100);
    }

    const errorCode = Object.keys(httpCode).find((x) => x === error.response.status.toString());

    if (error && error.response && errorCode !== undefined) {
      const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if ((token || Vue.prototype.$UserAccount) && error.response.status === 401) {
        const temp = new Date();
        const d = new Date(temp.setDate(temp.getDate() - 1));
        document.cookie = `token=;expires=${d}; path=/`;
        document.cookie = `CloudFront-Policy=; expires=${d}; path=/`;
        document.cookie = `CloudFront-Key-Pair-Id=; expires=${d}; path=/`;
        document.cookie = `CloudFront-Signature=; expires=${d}; path=/`;
        Vue.prototype.$setUserAccount();
        httpCode[error.response.status](error.response.data.error);

        router.push('/login').catch(() => {});
        return Promise.resolve({ data: { statusCode: 99999 } });
      }
      if (error.response.status !== 401) {
        httpCode[error.response.status](error.response.data.error);
      }
    } else {
      Vue.prototype.$bus.$emit('pushMessage', 'error', '操作失敗，請重新操作');
      return Promise.resolve({ data: { statusCode: 99999 } });
    }

    return Promise.reject(error);
  },
);

export default baseAxios;
