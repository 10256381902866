import baseAxios from '@/api/base';

// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** Homelinks api */
/**
 * 取得相關連結列表
 * @param {object} data {  }
 */
const GetHomelinks = () => baseAxios.post('/FrontEnd/HomeLinks/homelinks');

export default GetHomelinks;
