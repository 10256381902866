const BookingRouter = [
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../../views/Booking/Bookings.vue'),
    meta: {
      title: '預約講堂',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/bookings/:id',
    name: 'booking',
    component: () => import('../../views/Booking/Booking.vue'),
    meta: {
      title: '預約講堂',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
];

export default BookingRouter;
