import Vue from 'vue';
import VueRouter from 'vue-router';
// import Index from '../views/Index.vue';
import BookingRouter from './modules/BookingRouter';
import TeacherRouter from './modules/TeacherRouter';
import LearningRouter from './modules/LearningRouter';
import PracticeRouter from './modules/PracticeRouter';
import MemberRouter from './modules/MemberRouter';
import ShoppingRouter from './modules/ShoppingRouter';
import CourseRouter from './modules/CourseRouter';
import NewsRouter from './modules/NewsRouter';

Vue.use(VueRouter);
/* global $ */
const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta: {
      title: '首頁',
      layout: 'mainframe',
      haveTitle: false, // 標題
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '會員登入',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/thirdAuthLogin',
    name: 'lineLoginRedirect',
    component: () => import('../views/LineLoginRedirect.vue'),
    meta: {
      title: '會員登入',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/thirdAuthBind',
    name: 'lineBindRedirect',
    component: () => import('../views/LineBindRedirect.vue'),
    meta: {
      title: '會員綁定',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      title: '搜尋結果',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/novices',
    name: 'novices',
    component: () => import('../views/Guide.vue'),
    meta: {
      title: '新手指南',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/novices/question',
    name: 'question',
    component: () => import('../views/Question.vue'),
    meta: {
      title: '常見問題',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/novices/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: '聯絡我們',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/novices/:type',
    name: '新手指南',
    component: () => import('../views/Novices.vue'),
    meta: {
      title: '新手指南',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import('../views/Videos.vue'),
    meta: {
      title: '試聽課程',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  ...BookingRouter,
  ...TeacherRouter,
  ...LearningRouter,
  ...PracticeRouter,
  ...MemberRouter,
  ...ShoppingRouter,
  ...CourseRouter,
  ...NewsRouter,
  {
    path: '/notsupport',
    name: 'notsupport',
    component: () => import('../views/NotSupport.vue'),
    meta: {
      title: '發生錯誤',
      layout: 'errorFrame',
      haveTitle: false, // 標題
      haveBreadcrumb: false, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/maintain',
    name: 'maintain',
    component: () => import('../views/Maintain.vue'),
    meta: {
      title: '系統維護中',
      layout: 'errorFrame',
      haveTitle: false, // 標題
      haveBreadcrumb: false, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/error460',
    name: 'Error460',
    component: () => import('../views/Error460.vue'),
    meta: {
      title: '發生錯誤',
      layout: 'mainframe',
      haveTitle: true, // 標題
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      title: '發生錯誤',
      layout: 'mainframe',
      haveTitle: true, // 標題
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(Vue.prototype.$IsBinding);
  // // eslint-disable-next-line no-unused-expressions
  // if (Vue.prototype.$source) Vue.prototype.source.cancel;
  const routeTo = to;
  // const routeFrom = from;
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
  // if ((!token || token === '') && routeTo.name !== 'login') {
  //   next('/login');
  // }
  // // 檢查權限
  // if (
  //   to.name !== undefined
  //   && Object.values(Vue.prototype.$Authority).find((x) => x === to.name) === undefined
  //   && Object.values(Vue.prototype.$Authority).find((x) => x === `${to.name}s`) === undefined
  // ) {
  //   next('/');
  // }

  // if (routeTo.params.menu === true) {
  //   routeTo.meta.isBack = false;
  // }
  // console.log(routeTo.name, routeTo.params.fromIndex, from.meta.backIs, from.params.fromIndex);
  if (routeTo.name === from.meta.backIs) {
    routeTo.meta.keepAlive = true;
    routeTo.meta.isBack = true;
    // 從首頁到內頁的話，不保留查詢條件(因為沒有查詢條件)
    if (from.params.fromIndex) routeTo.meta.isBack = false;
  } else routeTo.meta.isBack = false;

  $('html,body').animate(
    {
      scrollTop: 0,
    },
    'slow',
  );

  if (routeTo.meta.needAuthority) {
    // routeTo.meta.to = 'learnings';
    if (!token || token === '') {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }
  }
  if (token || token !== '') {
    const { IsBinding } = JSON.parse(
      decodeURIComponent(
        escape(
          window.atob(
            token
              .split('.')[1]
              .replace(/-/g, '+')
              .replace(/_/g, '/'),
          ),
        ),
      ),
    );

    if (IsBinding === false && routeTo.meta.needAuthority && routeTo.name !== 'info') {
      if (from.name === 'info') {
        Vue.prototype.$bus.$emit('pushMessage', 'error', '使用會員功能請先驗證信箱與手機');
      }
      next({
        path: '/member/info',
      });
    }
  }

  window.document.title = `讀家雲端學院-${to.meta.title}`;

  if (routeTo.name !== 'maintain' && Vue.prototype.$MAINTAIN === '1') {
    next({
      path: '/maintain',
    });
  }

  if (routeTo.name === 'maintain' && Vue.prototype.$MAINTAIN === '0') {
    next({
      path: '/',
    });
  }

  const isIE = window.navigator.userAgent.indexOf('MSIE') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0;
  if (routeTo.name !== 'notsupport' && isIE) {
    if (isIE) {
      next({
        path: '/notsupport',
      });
    }
  }
  if (routeTo.name === 'login' && token !== '') {
    next({
      path: '/',
    });
  }
  // if (token !== '') {
  //   // 取得未讀訊息
  //   Vue.prototype.$bus.$emit('getUnReadCount');
  // }
  // if (to.name !== from.name) next();
  next();
});

export default router;
