import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/* Login api */
// 登入
export const Login = (data) => baseAxios.post('/FrontEnd/Login/Login', data);
// 登出
export const Logout = (data) => baseAxios.post('/FrontEnd/Login/Logout', data);
// Line登入
export const LineLogin = (data) => baseAxios.post('/FrontEnd/Login/GetThirdPartyLoginUrl', data);
// Line登入轉向取得Token
export const LineAuth = (data) => baseAxios.post('/FrontEnd/Login/GetThirdPartyLoginAuth', data);
