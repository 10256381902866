const ShoppingRouter = [
  {
    path: '/shopping/step1',
    name: 'Step1',
    component: () => import('../../views/Shopping/Step1.vue'),
    meta: {
      title: '購物車',
      layout: 'mainframe',
      shopping: true,
      haveTitle: true, // 標題
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/shopping/step2/:orderId?',
    name: 'Step2',
    component: () => import('../../views/Shopping/Step2.vue'),
    meta: {
      title: '填寫訂購資料',
      layout: 'mainframe',
      shopping: true,
      haveTitle: true, // 標題
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/shopping/step3/:orderId?',
    name: 'Step3',
    component: () => import('../../views/Shopping/Step3.vue'),
    meta: {
      title: '確定結帳',
      layout: 'mainframe',
      shopping: true,
      haveTitle: true, // 標題
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/shopping/finish',
    name: 'Finish',
    component: () => import('../../views/Shopping/Finish.vue'),
    meta: {
      title: '完成訂單',
      layout: 'mainframe',
      haveTitle: true, // 標題
      shopping: true,
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/shopping/failed',
    name: 'OrderPayFailed',
    component: () => import('../../views/Shopping/Failed.vue'),
    meta: {
      title: '訂單失敗',
      layout: 'mainframe',
      haveTitle: true, // 標題
      shopping: true,
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },

  {
    path: '/orderPay/step1/:id',
    name: 'OrderPayStep1',
    component: () => import('../../views/OrderPay/Step1.vue'),
    meta: {
      title: '待付款訂單',
      layout: 'mainframe',
      haveTitle: true, // 標題
      shopping: true,
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/orderPay/step2/:id',
    name: 'OrderPayStep2',
    component: () => import('../../views/OrderPay/Step2.vue'),
    meta: {
      title: '填寫訂購資料',
      layout: 'mainframe',
      haveTitle: true, // 標題
      shopping: true,
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/orderPay/step3/:id',
    name: 'OrderPayStep3',
    component: () => import('../../views/OrderPay/Step3.vue'),
    meta: {
      title: '確定結帳',
      layout: 'mainframe',
      haveTitle: true, // 標題
      shopping: true,
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/orderPay/finish/:id',
    name: 'OrderPayFinish',
    component: () => import('../../views/OrderPay/Finish.vue'),
    meta: {
      title: '完成訂單',
      layout: 'mainframe',
      haveTitle: true, // 標題
      shopping: true,
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
];

export default ShoppingRouter;
