const MemberRouter = [
  {
    path: '/member',
    name: 'member',
    component: () => import('../../views/Error.vue'),
    meta: {
      title: '會員中心',
      layout: 'mainframe',
      needAuthority: true, // 權限
    },
  },
  {
    path: '/member/info',
    name: 'info',
    component: () => import('../../views/Member/MemberInfo.vue'),
    meta: {
      title: '會員資訊',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/modify',
    name: 'modify',
    component: () => import('../../views/Member/MemberModify.vue'),
    meta: {
      title: '會員資料修改',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/changePassword',
    name: 'changePassword',
    component: () => import('../../views/Member/ChangePassword.vue'),
    meta: {
      title: '會員密碼修改',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/message',
    name: 'message',
    component: () => import('../../views/Member/Message.vue'),
    meta: {
      title: '會員訊息',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/orders',
    name: 'orders',
    component: () => import('../../views/Member/Orders.vue'),
    meta: {
      title: '訂單查詢',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/orders/:id',
    name: 'Order',
    component: () => import('../../views/Member/Order.vue'),
    meta: {
      title: '訂單查詢',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'orders',
    },
  },
  {
    path: '/member/register',
    name: 'register',
    component: () => import('../../views/Member/Register.vue'),
    meta: {
      title: '會員註冊',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/feedBacks',
    name: 'feedBacks',
    component: () => import('../../views/Member/FeedBacks.vue'),
    meta: {
      title: '意見回饋',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/member/feedBacks/:id',
    name: 'feedBack',
    component: () => import('../../views/Member/FeedBack.vue'),
    meta: {
      title: '意見回饋',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'feedBacks',
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../../views/Member/Forgot.vue'),
    meta: {
      title: '忘記密碼',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/forgotAccount',
    name: 'forgotAccount',
    component: () => import('../../views/Member/ForgotAccount.vue'),
    meta: {
      title: '忘記帳號',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
];

export default MemberRouter;
