<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb cs-breadcrumb bg-white align-items-center px-0">
      <!-- <li
        class="breadcrumb-item"
        :class="{ active: currentPage === item.title }"
        v-for="(item, index) in breadcrumbItem"
        :key="'breadcrumbItem' + index"
      >
        <router-link
          :to="{
            name: item.title === '會員中心' ? '' : item.name,
            params: item.params,
          }"
          >{{ item.title }}</router-link>
      </li> -->

      <li
        class="breadcrumb-item"
        :class="{ active: currentPage === item.title }"
        v-for="(item, index) in breadcrumbItem"
        :key="'breadcrumbItem' + index"
      >
        <router-link
          :to="{
            path: item.title === '會員中心' ? '' : item.path
          }"
          >{{ item.title }}</router-link>
      </li>
      <!-- <li class="breadcrumb-item active" aria-current="page">
        <a href="#">2020 周易的刑法考前速成讀書會</a>
      </li> -->
    </ol>
  </nav>
</template>

<script>
export default {
  props: ['lastItem', 'currentPage'],
  data() {
    return {
      current: [],
      breadcrumbItem: [],
    };
  },
  watch: {
    $route() {
      this.current = [];
      this.SetData();
    },
    currentPage() {
      this.current = this.currentPage;
      this.SetData();
    },
  },
  created() {
    this.SetData();
  },
  methods: {
    SetData() {
      const data = this.$route.path.toLowerCase().split('/');

      const test = this._.filter(
        this.$router.options.routes,
        (o) => this._.indexOf(data, o.name.toLowerCase()) >= 0,
      );

      this.breadcrumbItem = this._.map(test, (o) => ({
        name: o.name,
        title: o.meta.title,
        path: o.path,
        params: {},
      }));

      // 如果目前網址的路由名字不為空白，則統一小寫 (這邊是從頁面傳送過來，並非路由本身)
      const currentToLow = this._.map(this.current, (o) => (o.name === undefined ? '' : o.name.toLowerCase()));

      // 原本路由跟目頁面傳送過來的比對，如果已經存在於頁面傳來的，則保留頁面傳來的(因為頁面傳來的會有動態的標題名稱)
      const keepRoute = this._.filter(
        this.breadcrumbItem,
        (o) => this._.indexOf(currentToLow, o.name.toLowerCase()) < 0,
      );
      // 當前路由超過1個節點時，才需要加上後續路由
      if (data.length - 1 >= 2) this.breadcrumbItem = keepRoute.concat(this.current);
    },
  },
};
</script>

<style></style>
