const NewsRouter = [
  {
    path: '/news',
    name: 'news',
    component: () => import('../../views/News/News.vue'),
    meta: {
      title: '最新消息',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    component: () => import('../../views/News/NewsDetail.vue'),
    meta: {
      title: '最新消息',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
      backIs: 'news',
    },
  },
];

export default NewsRouter;
