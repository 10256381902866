<template>
  <div class="layout">
    <Navbar />
    <Content />
    <Footer />
    <!-- Top -->
    <a href="" id="gotop" class="btn-hover-focus"><i class="fa fa-angle-up"></i></a>
  </div>
</template>

<script>
import Navbar from '@/components/layouts/Navbar.vue';
import Content from '@/components/layouts/Content.vue';
import Footer from '@/components/layouts/Footer.vue';

/* global $ */
export default {
  components: {
    Navbar,
    Content,
    Footer,
  },
  mounted() {
    // Top
    let s;
    $(window).scroll(() => {
      s = $(document).scrollTop();
      /* 偵測卷軸滑動時，往下滑超過400px就讓GoTop按鈕出現 */

      if (s > $('#header').outerHeight()) {
        $('#header').addClass('mini-nav');
        $('#gotop').fadeIn();
      } else {
        $('#header').removeClass('mini-nav');
        $('#gotop').fadeOut();
      }
    });

    $('#gotop').click(() => {
      $('html,body').animate(
        {
          scrollTop: 0,
        },
        'slow',
      );
      /* 返回到最頂上 */
      return false;
    }); // search
  },
};
</script>

<style></style>
