const PracticeRouter = [
  {
    path: '/practices',
    name: 'practices',
    component: () => import('../../views/Practice/Practices.vue'),
    meta: {
      title: '測驗去',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
    },
  },
  {
    path: '/practices/:id',
    name: 'practicesCategory',
    component: () => import('../../views/Practice/Category.vue'),
    meta: {
      title: '測驗去',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'practices',
    },
  },
  {
    path: '/practices/exercise/:id',
    name: 'exercise',
    component: () => import('../../views/Practice/Exercise.vue'),
    meta: {
      title: '練習去',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'practicesCategory',
    },
  },
  {
    path: '/practices/knowledge/:id',
    name: 'knowledge',
    component: () => import('../../views/Practice/Knowledge.vue'),
    meta: {
      title: '知識點分析',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'practicesCategory',
    },
  },
  {
    path: '/practices/exam/:id',
    name: 'exam',
    component: () => import('../../views/Practice/Exam.vue'),
    meta: {
      title: '考試去',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'practicesCategory',
    },
  },
  {
    path: '/practices/examHistory/:id',
    name: 'examHistory',
    component: () => import('../../views/Practice/ExamHistory.vue'),
    meta: {
      title: '試卷紀錄',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: true, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'practicesCategory',
    },
  },
  {
    path: '/practices/examReview/:id/:examId',
    name: 'examReview',
    component: () => import('../../views/Practice/ExamReview.vue'),
    meta: {
      title: '試卷紀錄',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: true, // 權限
      isBack: false,
      backIs: 'examHistory',
    },
  },
];

export default PracticeRouter;
