const CourseRouter = [
  {
    path: '/course', // 第一層
    name: 'course',
    component: () => import('../../views/Course/Classification.vue'),
    meta: {
      title: '購買課程',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/course/classRoom', // 第二層
    name: 'ClassRoom',
    component: () => import('../../views/Course/ClassRoom.vue'),
    meta: {
      title: '購買課程',
      layout: 'mainframe',
      haveTitle: true, // 標題
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
  {
    path: '/course/classRoom/:id', // 明細
    name: 'CourseDetailed',
    component: () => import('../../views/Course/CourseDetailed.vue'),
    meta: {
      title: '購買課程',
      layout: 'mainframe',
      haveTitle: true, // 標題
      haveBreadcrumb: true, // 麵包屑
      keepAlive: false, // 缓存
      needAuthority: false, // 權限
      isBack: false,
    },
  },
];

export default CourseRouter;
